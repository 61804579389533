
import CheckBox from "@/components/CheckBox.vue";
import { messaging, messagingSupported } from "@/utils/firebaseInstance";
import { registerFCM } from "@/services/userService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "NotificationArea",
  components: { CheckBox },
  data() {
    return {
      notificationSound: false,
      FCMSupported: messagingSupported,
      pushNotification: JSON.parse(localStorage["pushNotification"] || "false"),
      notificationMentionsOnly: false,
    };
  },
  mounted() {
    this.configureNotificationSound();
    this.configureNotificationMentionsOnly();
  },
  methods: {
    configureNotificationSound() {
      const state = localStorage["notificationSoundDisabled"];
      if (state === undefined) {
        this.notificationSound = true;
        return;
      }
      this.notificationSound = !JSON.parse(state);
    },
    notificationSoundToggled(state: boolean) {
      this.notificationSound = state;
      localStorage["notificationSoundDisabled"] = !state;
    },
    configureNotificationMentionsOnly() {
      const state = localStorage["notificationMentionsOnly"];
      if(state === undefined) {
        this.notificationMentionsOnly = false;
        return;
      }
      this.notificationMentionsOnly = JSON.parse(state);
    },
    notificationMentionsOnlyToggled(state: boolean) {
      this.notificationMentionsOnly = state;
      //Should be fine since not inverted like L:65.
      localStorage["notificationMentionsOnly"] = state;
    },
    pushNotificationToggled(state: boolean) {
      if (!state) {
        messaging().deleteToken();
        this.pushNotification = state;
        localStorage["pushNotification"] = state;
        return;
      }
      Notification.requestPermission((status) => {
        if (status !== "granted") {
          alert("Permission for notifications is blocked.");
          this.pushNotification = false;
          localStorage["pushNotification"] = false;
          return;
        }
        // enable notification
        this.pushNotification = true;
        localStorage["pushNotification"] = true;
        this.registerFCMNotification();
      });
    },
    registerFCMNotification() {
      messaging()
        .getToken()
        .then((token) => {
          registerFCM(token);
        });
    },
  },
});
